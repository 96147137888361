exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-models-paediatric-approach-js": () => import("./../../../src/pages/interactive-models/paediatric-approach.js" /* webpackChunkName: "component---src-pages-interactive-models-paediatric-approach-js" */),
  "component---src-pages-refer-js": () => import("./../../../src/pages/refer.js" /* webpackChunkName: "component---src-pages-refer-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

