// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "./src/styles/tailwind.css"

export function onServiceWorkerUpdateReady() {
  window.location.reload(true)
}
